import React, { useState } from 'react';

import { Form, Modal } from 'antd';

import { useForm } from 'antd/lib/form/Form';

import { MODAL_FORM_LAYOUT_LABEL } from '../utils/midas-constants';

import AdvancedProductSearchForm, { AdvancedProductSearchFormInitialValues } from './components/AdvancedProductSearchForm';

import classes from './AdvancedProductSearchModal.module.css';

type AdvancedProductSearchModalParams = {
  onSearchCancel?: () => void;
  onSearchComplete: (addedProducts: string[]) => void;
  visible: boolean;
};

const DEFAULT_PROPS = {
  onSearchCancel: () => {},
};

export default function AdvancedProductSearchModal({
  onSearchCancel = DEFAULT_PROPS.onSearchCancel,
  onSearchComplete,
  visible,
}: AdvancedProductSearchModalParams) {
  const [formInstance] = useForm();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const onComplete = () => {
    onSearchComplete(selectedProducts);
  };

  return (
    <Modal
      className={classes.advancedSearchModal}
      destroyOnClose
      maskClosable={false}
      okText="Done"
      onCancel={onSearchCancel}
      onOk={onComplete}
      title="Find Products"
      open={visible}
      width={850}
    >
      <Form
        form={formInstance}
        labelCol={MODAL_FORM_LAYOUT_LABEL}
        layout="horizontal"
        initialValues={AdvancedProductSearchFormInitialValues}
      >
        <AdvancedProductSearchForm formInstance={formInstance} onChange={setSelectedProducts} />
      </Form>
    </Modal>
  );
}
