import React, { useCallback } from 'react';
import { Modal, Space, Form, Switch, Button } from 'antd';
import { UserPreferencesModel } from '../models/user-preferences';
import { Spinner } from '../../../_shared/Spinner';

import styles from './UserPreferencesDialog.module.css';
import useResponsive from '../../../hooks/use-responsive';

export interface UserPreferencesDelegate {
  onOpenUserPreferences(): void;
  onCancelUserPreferences(): void;
  onSaveUserPreferences(): void;
  onEditStaleInstancesAlertsEnabled(checked: boolean): void;
}

export function UserPreferencesDialog({ model, delegate }: { model: UserPreferencesModel; delegate: UserPreferencesDelegate }) {
  const handleCancel = useCallback(() => delegate.onCancelUserPreferences(), [delegate]);
  const handleConfirm = useCallback(() => delegate.onSaveUserPreferences(), [delegate]);

  const { isMobile } = useResponsive();

  return (
    <Modal title="Preferences" maskClosable={false} open onCancel={handleCancel} onOk={handleConfirm} okText="Save" destroyOnClose>
      {model.loading && <Spinner />}

      {!model.loading && (
        <Space direction="vertical" className="w-full" size="middle">
          <div className={styles.preferencesContainer}>
            <strong>Notifications</strong>

            <Form.Item label="Stale instance email alerts" className={styles.preferenceItem}>
              <Switch
                id="wb_stale_emails_enabled"
                aria-label="Stale instance email alerts enabled?"
                checked={model.preferences?.staleInstancesAlertsEnabled}
                onChange={(checked) => delegate.onEditStaleInstancesAlertsEnabled(checked)}
              />
            </Form.Item>
          </div>
        </Space>
      )}
    </Modal>
  );
}
