import React, { useCallback, useContext } from 'react';
import isFunction from 'lodash/isFunction';

import { createHeader } from '../pages/data-query/components';
import { DataQueryFormItem } from '../pages/types';

import { QueryConfigContext } from './query-config-context';

import { SettingsConfigContext } from './settings-config-context';

type QueryContextProviderWrapperParams = {
  children: JSX.Element;
};

export default function QueryContextProviderWrapper({ children }: QueryContextProviderWrapperParams) {
  const { appConfig, updateAppConfig: setStoredSearchQueries } = useContext(SettingsConfigContext);

  const { previousSearches, searchConfigs, loading } = appConfig;

  const setSearchConfigs = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (newSearchConfigs: DataQueryFormItem[] | Function, deletedSearchConfig?: DataQueryFormItem) => {
      setStoredSearchQueries((u) => ({
        ...u,
        searchConfigs: isFunction(newSearchConfigs) ? newSearchConfigs(u.searchConfigs) : newSearchConfigs,
        previousSearches: deletedSearchConfig
          ? [
              {
                ...deletedSearchConfig,
                deletedAt: new Date().toISOString(),
              },
              ...u.previousSearches!.slice(0, 9),
            ]
          : [...u.previousSearches!],
      }));
    },
    [setStoredSearchQueries]
  );

  const removePreviousSearchConfig = useCallback(
    (deletedSearchId: string, searchType: string) => {
      // shelved searches are under searchConfigs, while deleted are under previousSearches
      const search = searchType === 'deleted' ? 'previousSearches' : 'searchConfigs';

      setStoredSearchQueries((u) => ({
        ...u,
        [search]: (u[search] as { uuid: string }[])?.filter(({ uuid }) => uuid !== deletedSearchId),
      }));
    },
    [setStoredSearchQueries]
  );

  const selectPreviousSearchConfig = useCallback(
    (candidate: DataQueryFormItem, searchType: string, routeType: string) => {
      if (!['deleted', 'shelved'].includes(searchType)) {
        return;
      }

      const newTab = {
        type: routeType.substring(1),
        name: candidate.name,
        uuid: candidate.uuid,
      };

      if (searchType === 'shelved') {
        setStoredSearchQueries((currentConfig) => ({
          ...currentConfig,
          tabs: [...currentConfig.tabs!, newTab],
          searchConfigs: currentConfig.searchConfigs?.map((config) =>
            config.uuid === candidate.uuid ? { ...config, shelved: false } : config
          ),
        }));

        return;
      }

      // deleted search type
      setStoredSearchQueries((currentConfig) => ({
        ...currentConfig,
        tabs: [...currentConfig.tabs!, newTab],
        searchConfigs: [...currentConfig.searchConfigs!, candidate],
        previousSearches: currentConfig.previousSearches?.filter(({ uuid }) => uuid !== candidate.uuid),
      }));
    },
    [setStoredSearchQueries]
  );

  const updateSearchConfig = useCallback(
    (id: string, delta: string[]) => {
      const updatedSearchConfigs: DataQueryFormItem[] = searchConfigs!.map((item) =>
        item.uuid !== id
          ? item
          : createHeader({
              ...item,
              config: { ...item.config, ...delta },
            })
      );
      setSearchConfigs(updatedSearchConfigs);
      return updatedSearchConfigs.find(({ uuid }) => uuid === id);
    },
    [searchConfigs, setSearchConfigs]
  );

  const updateMetaConfig = useCallback(
    (id: string, delta: DataQueryFormItem | ((a: unknown) => object)) => {
      setSearchConfigs(
        searchConfigs!.map((item) =>
          item.uuid !== id
            ? item
            : {
                ...item,
                meta: {
                  ...item.meta,
                  ...(isFunction(delta) ? delta(item.meta) : delta),
                },
              }
        )
      );
    },
    [searchConfigs, setSearchConfigs]
  );

  return (
    <QueryConfigContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        previousSearches,
        removePreviousSearchConfig,
        selectPreviousSearchConfig,
        searchConfigs,
        updateSearchConfig,
        setSearchConfigs,
        updateMetaConfig,
        loading,
      }}
    >
      {children}
    </QueryConfigContext.Provider>
  );
}
