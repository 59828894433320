import orderBy from 'lodash/orderBy';
import { GroupData, User } from './users-api';

export function collapseUsers(rawResponse: GroupData[]): User[] {
  const output: { [key: string]: User & { groups: string[] } } = {};

  rawResponse.forEach(({ groupName, members }) => {
    members.forEach(({ id, name, email }) => {
      if (output[id]) {
        output[id].groups.push(groupName);
        return;
      }

      output[id] = {
        title: '',
        value: id,
        name,
        email,
        selectable: true,
        groups: [groupName],
      };
    });
  });

  const allItems: User[] = Object.values(output).map(({ groups, ...item }) => ({
    ...item,
    title: `${item.name} (${groups.join(', ')})`,
  }));

  return orderBy(allItems, 'name');
}
