import React, { useCallback, useEffect } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import SideNav, { SideNavProps } from './side-nav/SideNav';
import { classNameFromState, SecondarySidebar, SecondarySidebarState } from './secondary-sidebar/SecondarySidebar';
import { WorkbenchInstanceFakeContainer } from './pages/instances/views/WorkbenchInstanceFakeContainer';
import { SideNavRouteProps } from './_shared/AppRoutes';
import { NotificationsListModel } from './secondary-sidebar/controls/notifications/models/notifications-list';
import { NotificationController } from './secondary-sidebar/controls/notifications/notifications-controller';
import { useInstances } from './pages/instances/instances-hooks';

interface AppContentWbInsideZoeProps {
  navParams: SideNavProps & SideNavRouteProps;
  sidebarMenuCollapsed: boolean;
  setSidebarMenuCollapsed: (collapsed: boolean) => void;
  sidebarNotificationsCollapsed: SecondarySidebarState;
  setSidebarNotificationsCollapsed: (state: SecondarySidebarState) => void;
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationController;
  setInstancesModel: (model: any) => void;
  setInstancesDelegate: (delegate: any) => void;
}

const { Content } = Layout;
export function AppContentWbInsideZoe({
  navParams,
  sidebarMenuCollapsed,
  setSidebarMenuCollapsed,
  sidebarNotificationsCollapsed,
  setSidebarNotificationsCollapsed,
  notificationsModel,
  notificationsDelegate,
  setInstancesModel,
  setInstancesDelegate,
}: AppContentWbInsideZoeProps) {
  const visibleSidebar = sidebarNotificationsCollapsed !== SecondarySidebarState.Hidden;
  const { instancesModel, instancesDelegate } = useInstances();

  useEffect(() => {
    if (instancesModel) {
      setInstancesModel(instancesModel);
    }
  }, [instancesModel, setInstancesModel]);

  useEffect(() => {
    if (instancesDelegate) {
      setInstancesDelegate(instancesDelegate);
    }
  }, [instancesDelegate, setInstancesDelegate]);

  if (!instancesDelegate) {
    return <></>;
  }

  return (
    <>
      <SideNav
        {...navParams}
        sidebarCollapsed={sidebarMenuCollapsed}
        onSidebarCollapsed={setSidebarMenuCollapsed}
        notificationsCollapsed={sidebarNotificationsCollapsed}
        onNotificationsCollapsed={setSidebarNotificationsCollapsed}
        instancesModel={instancesModel}
        instancesDelegate={instancesDelegate}
      />
      <Content className={classNameFromState(sidebarNotificationsCollapsed)}>
        <Outlet />
        <WorkbenchInstanceFakeContainer model={instancesModel} delegate={instancesDelegate} />
      </Content>
      {visibleSidebar && (
        <SecondarySidebar
          notificationsModel={notificationsModel}
          notificationsDelegate={notificationsDelegate}
          sidebarCollapsed={sidebarNotificationsCollapsed === SecondarySidebarState.Collapsed}
          onSidebarCollapsedChanged={(collapsed) =>
            setSidebarNotificationsCollapsed(collapsed ? SecondarySidebarState.Collapsed : SecondarySidebarState.Visible)
          }
        />
      )}
    </>
  );
}
