import React from 'react';
import { Col, Row } from 'antd';

import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { createSorterCallback, createSorterFactory } from '../../pages/data-query/components/outputs/_shared/table-utils';
import { DataQueryFormItemMetaAccordionControls, DataQueryFormItemMetaTableConfig, DataQueryTableConfigSorting } from '../../pages/types';

import DpActivityJobs from './DpActivityJobs';
import DpActivityAvailableData from './DpActivityAvailableData';
import DpActivityFiles from './DpActivityFiles';
import UserDefinedTablesView from './UserDefinedTablesView';

import classes from './DpActivity.module.css';

type DpActivityParams = {
  accordionActiveKeys: DataQueryFormItemMetaAccordionControls;
  onAccordionActiveKeysChanged: (key: string, openControls: string | string[]) => void;
  tablesSize: SizeType;
  tableConfig?: { sorting?: DataQueryTableConfigSorting };
  onTableConfigChanged?: (fb: (value: DataQueryFormItemMetaTableConfig) => DataQueryFormItemMetaTableConfig) => void;
  onSelectedWorkflowIdChanged?: React.Dispatch<React.SetStateAction<string | string[] | undefined>>;
  selectedWorkflowId?: string | string[];
  showActiveOnly?: boolean;
  showDeletedFiles?: boolean;
  includePublicData?: boolean;
};

const DEFAULT_PROPS = {
  tableConfig: {},
};

export default function DpActivity({
  accordionActiveKeys,
  onAccordionActiveKeysChanged,
  tableConfig = DEFAULT_PROPS.tableConfig,
  onTableConfigChanged,
  tablesSize = 'large',
  showActiveOnly = false,
  showDeletedFiles = false,
  includePublicData = false,
  onSelectedWorkflowIdChanged,
  selectedWorkflowId,
}: DpActivityParams) {
  const handleTableChangeCallbackFactory = createSorterCallback(onTableConfigChanged);

  const sorterFactory = onTableConfigChanged ? createSorterFactory(tableConfig?.sorting) : undefined;

  const conditionallyHandleShowRelatedItemsInfo = !selectedWorkflowId && ((id: string | string[]) => onSelectedWorkflowIdChanged?.(id));

  return (
    <div className={classes.dpActivityContainer}>
      {window.CONFIG.featureFlags.useUserDataLake && (
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <UserDefinedTablesView
              tablesSize={tablesSize}
              sorterFactory={sorterFactory}
              handleTableChangeCallbackFactory={handleTableChangeCallbackFactory}
              accordionActiveKeys={accordionActiveKeys}
              onAccordionActiveKeysChanged={onAccordionActiveKeysChanged}
              includePublicData={includePublicData}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 8]}>
        <Col span={24}>
          <DpActivityJobs
            tablesSize={tablesSize}
            sorterFactory={sorterFactory}
            selectedWorkflowId={selectedWorkflowId}
            conditionallyHandleShowRelatedItemsInfo={conditionallyHandleShowRelatedItemsInfo}
            handleTableChangeCallbackFactory={handleTableChangeCallbackFactory}
            accordionActiveKeys={accordionActiveKeys}
            onAccordionActiveKeysChanged={onAccordionActiveKeysChanged}
            showActiveOnly={showActiveOnly}
            showDeletedFiles={showDeletedFiles}
          />
        </Col>
      </Row>

      <Row gutter={[16, 8]}>
        <Col span={24}>
          <DpActivityAvailableData
            tablesSize={tablesSize}
            sorterFactory={sorterFactory}
            selectedWorkflowId={selectedWorkflowId}
            conditionallyHandleShowRelatedItemsInfo={conditionallyHandleShowRelatedItemsInfo}
            handleTableChangeCallbackFactory={handleTableChangeCallbackFactory}
            accordionActiveKeys={accordionActiveKeys}
            onAccordionActiveKeysChanged={onAccordionActiveKeysChanged}
            showActiveOnly={showActiveOnly}
            showDeletedFiles={showDeletedFiles}
            includePublicData={includePublicData}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <DpActivityFiles
            tablesSize={tablesSize}
            sorterFactory={sorterFactory}
            selectedWorkflowId={selectedWorkflowId}
            conditionallyHandleShowRelatedItemsInfo={conditionallyHandleShowRelatedItemsInfo}
            handleTableChangeCallbackFactory={handleTableChangeCallbackFactory}
            accordionActiveKeys={accordionActiveKeys}
            onAccordionActiveKeysChanged={onAccordionActiveKeysChanged}
            showActiveOnly={showActiveOnly}
            showDeletedFiles={showDeletedFiles}
          />
        </Col>
      </Row>
    </div>
  );
}
