import { useEffect, useState } from 'react';

import toPairs from 'lodash/toPairs';

import { getAccessTokenFromCookie } from '../../../../../../services/auth';

import { DIP_CONFIG_URL } from '../../../../../utils/midas-constants';
import { getToJson } from '../../../../../utils/io';

import { DataQueryFeed, WorkflowQueryOptions, WorkflowQueryOptionsValues } from '../../../../types';

export default function useCacheOrFetch(source: 'twxm' | 'lake', options: WorkflowQueryOptions) {
  const [data, setData] = useState(options);

  useEffect(() => {
    if (options.feedsAndGroupsTree.length && options.values) {
      setData((prevData) => ({ ...prevData, loading: false }));
      return;
    }

    void (async () => {
      try {
        const { feedsAndGroups, values: rawValues } = await getToJson(DIP_CONFIG_URL, getAccessTokenFromCookie());
        const feedsAndGroupsTree: DataQueryFeed[] = toPairs(feedsAndGroups[source] as string[][]).map(([feedName, feeds]) => ({
          key: feedName,
          value: feedName,
          title: feedName,
          selectable: true,
          children: feeds.map((feed) => ({
            key: `${feedName}/${feed}`,
            value: `${feedName}/${feed}`,
            title: feed,
            selectable: true,
          })),
        }));

        const values = (rawValues as { name: string; supportsLevel: string }[]).reduce(
          (result: WorkflowQueryOptionsValues, { name, supportsLevel }) => {
            result.options.push({ value: name });

            if (supportsLevel) {
              result.supportLevelValues.push(name);
            }

            return result;
          },
          {
            options: [],
            supportLevelValues: [],
          }
        );

        // TODO: cache or inform Wizard here?
        setData({ feedsAndGroupsTree, values, loading: false });
      } catch (error) {
        console.error('[useCacheOrFetch] failed to load sources from server..', error);
        setData((prevData) => ({ ...prevData, loading: false }));
      }
    })();
  }, [options, source]);

  return data;
}
