import React, { useCallback, useContext, useState } from 'react';

import { Button, Col, Row, Space, Switch, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { PaddedContainer } from '../../../_shared/PaddedContainer';
import PageHeader from '../../../_shared/PageHeader';

import { InfoDialog } from '../../../pages/jobs/views/InfoDialog';
import { UserInfoModel } from '../../../pages/user-info/models/user-info';

import { SettingsConfigContext } from '../../context/settings-config-context';
import { HistoryContext } from '../../context/history-context';
import DpActivity from '../../components/dpActivity/DpActivity';

import { ACTIVITY_TAB_ID } from '../../utils/midas-constants';

import HistoryProxy from '../data-query/components/history-proxy';

import { DataQueryFormItemMetaTableConfig } from '../types';

import classes from './DpActivityPage.module.css';

export const DEFAULT_OPEN_CONTROLS = { tables: ['tables'], active: ['active'], data: ['data'], files: ['files'] };

function DialogContent() {
  return (
    <>
      <p>In this page you can view the history and manage the data produced by a Workflow.</p>
    </>
  );
}

export default function DpActivityPage({ userInfoModel }: { userInfoModel: UserInfoModel }) {
  const { updateTabById, getTabById } = useContext(SettingsConfigContext);
  const { eventHandler } = useContext(HistoryContext);

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | string[] | undefined>(undefined);
  const [dialogVisible, setDialogVisible] = useState(false);

  const stateId = ACTIVITY_TAB_ID;

  const metaData = getTabById(stateId)?.meta;

  const openAccordionControls = metaData?.openAccordionControls || DEFAULT_OPEN_CONTROLS;
  const tableConfig = metaData?.tableConfig || {};

  const headerOptions = {
    showActiveOnly: {
      value: metaData?.showActiveOnly || false,
      disabled: Boolean(selectedWorkflowId),
      label: 'Active Data Only:',
      id: 'activeDataOnly',
    },
    includePublicData: {
      value: metaData?.includePublicData || false,
      disabled: Boolean(selectedWorkflowId),
      label: userInfoModel.isAdmin ? 'Show All Tables:' : 'Show All Readable Tables:',
      id: 'showPublicTables',
    },
    showDeletedFiles: {
      value: metaData?.showActiveOnly ? false : metaData?.showDeletedFiles || false,
      disabled: Boolean(selectedWorkflowId || metaData?.showActiveOnly),
      label: 'Show Deleted Files:',
      id: 'showDeletedFiles',
    },
  };

  const showInfoDialog = () => {
    setDialogVisible(true);
  };

  const hideInfoDialog = () => {
    setDialogVisible(false);
  };

  const handleAccordionControlsChanged = useCallback(
    (key: string, openControls: string | string[]) => {
      updateTabById(stateId, (data) => {
        return {
          ...data,
          meta: {
            ...data.meta,
            openAccordionControls: {
              ...data.meta?.openAccordionControls,
              [key]: openControls,
            },
          },
        };
      });
    },
    [stateId, updateTabById]
  );

  const handleHeaderOptionChange = useCallback(
    (optionToChange: string, value: unknown) => {
      updateTabById(stateId, (data) => ({
        ...data,
        meta: {
          ...data.meta,
          [optionToChange]: value,
        },
      }));
    },
    [stateId, updateTabById]
  );

  const handleTableConfigChanged = useCallback(
    (changeCallback: (value: DataQueryFormItemMetaTableConfig) => DataQueryFormItemMetaTableConfig) => {
      updateTabById(stateId, (data) => ({
        ...data,
        meta: {
          ...data.meta,
          tableConfig: changeCallback(data.meta!.tableConfig!),
        },
      }));
    },
    [stateId, updateTabById]
  );

  const limitingHeader = selectedWorkflowId && (
    <Space size="small">
      <div className="workflowItemsLabel">
        <InfoCircleOutlined /> &nbsp;
        {`Viewing items relating to workflow ID: ${Array.isArray(selectedWorkflowId) ? selectedWorkflowId[0] : selectedWorkflowId}.`}
      </div>
      <Button className={classes.clearButton} onClick={() => setSelectedWorkflowId(undefined)} type="link">
        Clear
      </Button>
    </Space>
  );

  return (
    <PaddedContainer>
      <HistoryProxy eventHandler={eventHandler!} />
      <PageHeader
        headerText={
          <div className="pageTitleWithInfoIcon">
            My Data
            <Tooltip title={'What is My Data Activity?'} placement="top">
              <Button
                icon={<InfoCircleOutlined style={{ marginLeft: '5px' }} />}
                onClick={showInfoDialog}
                className="infoIcon"
                data-testid="show-page-info"
              />
            </Tooltip>
          </div>
        }
        rightButtonsContainer={
          <Space direction="vertical" className={classes.verticalSpace}>
            <Space className={classes.horizontalSpace} size="middle">
              {Object.entries(headerOptions).map(([key, { value, id, label, disabled }]) => (
                <React.Fragment key={key}>
                  <div>
                    <label className="ant-typography" htmlFor={id}>
                      {label}
                    </label>
                    &nbsp;
                    <Switch
                      aria-label={label}
                      id={id}
                      onChange={(newValue) => handleHeaderOptionChange(key, newValue)}
                      disabled={disabled}
                      checked={value}
                    />
                  </div>
                </React.Fragment>
              ))}
            </Space>
            {limitingHeader}
          </Space>
        }
      />

      <Row gutter={[16, 8]}>
        <Col span={24}>
          <DpActivity
            accordionActiveKeys={openAccordionControls}
            onAccordionActiveKeysChanged={handleAccordionControlsChanged}
            tablesSize="middle"
            showActiveOnly={headerOptions.showActiveOnly.value}
            includePublicData={headerOptions.includePublicData.value}
            showDeletedFiles={headerOptions.showDeletedFiles.value}
            onTableConfigChanged={handleTableConfigChanged}
            tableConfig={tableConfig}
            onSelectedWorkflowIdChanged={setSelectedWorkflowId}
            selectedWorkflowId={selectedWorkflowId}
          />
        </Col>
      </Row>
      {dialogVisible && (
        <InfoDialog
          model={{
            title: 'What is My Data Activity?',
            content: <DialogContent />,
            docsSection: 'Data Query, Workflow and Activity',
            docsPath: 'resources/Data-Query-main.md',
          }}
          delegate={{ closeDialog: hideInfoDialog }}
        />
      )}
    </PaddedContainer>
  );
}
