import { useCallback, useEffect, useState } from 'react';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';
import { ROOT_SERVER_URL } from '../../../../../utils/midas-constants';
import { GetUrlForQuery } from './use-data-requester';
import { DataQueryConfig, DataQueryProductRow } from '../../../../types';
import { getAccessTokenFromCookie } from '../../../../../../services/auth';

type useIndexDataRequesterParams = {
  config?: DataQueryConfig;
  getUrlForQuery: GetUrlForQuery;
  getDownloadUrlForQuery?: GetUrlForQuery;
  clearBeforeLoad?: boolean;
};

export default function useIndexDataRequester({ config, getUrlForQuery, getDownloadUrlForQuery }: useIndexDataRequesterParams) {
  const [pageIndexData, setPageIndexData] = useState<{ busy: boolean; error?: string | object; data: DataQueryProductRow[] }>({
    busy: false,
    error: undefined,
    data: [],
  });

  const [product, setProduct] = useState('');

  useEffect(() => {
    if (!config || !config.products || config.products.length === 0 || (product && config.products.includes(product))) {
      return;
    }

    setProduct(config.products[0]);
  }, [config, product]);

  useEffect(() => {
    if (!config) {
      return;
    }

    setPageIndexData({
      busy: true,
      data: [],
      error: undefined,
    });

    void (async () => {
      try {
        const url = getUrlForQuery(config);
        if (!url) {
          throw new Error('Could not get products since an empty url was provided');
        }

        const definedUrl: string = url as string;

        if (!url) {
          setPageIndexData((s) => ({
            ...s,
            busy: false,
          }));
          return;
        }

        const data: DataQueryProductRow[] | undefined = await checkAxiosResponse(
          axios.get(definedUrl, { headers: { Authorization: `Bearer ${getAccessTokenFromCookie()}` }, validateStatus: null }),
          'Error while getting products'
        );

        if (!data) {
          throw new Error('Error while getting products');
        }

        setPageIndexData({
          data,
          busy: false,
          error: undefined,
        });
      } catch (error: unknown) {
        console.error('[useIndexDataRequester] failed to get index...', error);
        setPageIndexData({
          data: [],
          busy: false,
          error: error as object,
        });
      }
    })();
  }, [config, getUrlForQuery]);

  const downloadData = useCallback(
    (selectedItem: string) => {
      if (!config) {
        return;
      }

      void (async () => {
        try {
          const operation = getDownloadUrlForQuery || getUrlForQuery;
          if (!operation) {
            return;
          }

          const url = operation(config, selectedItem);
          if (!url) {
            throw new Error('Could not download data since an empty url was provided');
          }

          const definedUrl: string = url as string;

          const content = await checkAxiosResponse<string>(
            axios.get(definedUrl, { headers: { Authorization: `Bearer ${getAccessTokenFromCookie()}` }, validateStatus: null }),
            'Error while downloading data'
          );

          if (!content) {
            throw new Error('Error while downloading data');
          }

          window.location.href = ROOT_SERVER_URL + content;
        } catch (error) {
          console.error('[useIndexDataRequester] failed to generate download URL...', error);
        }
      })();
    },
    [config, getUrlForQuery, getDownloadUrlForQuery]
  );

  return {
    pageIndexData,
    setProduct,
    product,
    downloadData,
  };
}
