import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Form, FormInstance, Pagination, Radio, Table, Tag, Typography } from 'antd';
import debounce from 'lodash/debounce';
import defer from 'lodash/defer';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import uniqWith from 'lodash/uniqWith';
import { v4 as uuid } from 'uuid';
import { Dayjs } from 'dayjs';

import { getAccessTokenFromCookie } from '../../../services/auth';

import { DataQueryProducts, DataQueryProductsItem } from '../../pages/types';

import ErrorComponent from '../../components/Error';

import { REFERENCE_GET_TABLE_DATA_URL } from '../../utils/midas-constants';
import { getToJson } from '../../utils/io';

import { DEFAULT_PAGE_SIZE, FORM_VALUES, INITIAL_SEARCH_DATE, SUPPORTED_SOURCES } from '../_shared/advanced-search-constants';

import ProductDataFormFields from './ProductDataFormFields';
import ReferenceDataFormFields from './ReferenceDataFormFields';

import classes from '../AdvancedProductSearchModal.module.css';

export type AdvancedProductSearchFormParams = {
  formInstance: FormInstance;
  productInfoForm?: boolean;
  onChange?: (value: string[]) => void;
  embedded?: boolean;
};

export type AdvancedProductSearchCriteria = {
  id?: string;
  displayName?: string;
  field?: string;
  type?: string;
  value?: string | number | Dayjs;
  searchMethod?: string;
};

export type AdvancedProductSearchResultHeader = {
  [datasource: string]: { name: string; fieldType: 'date' | 'double' | 'int' | 'varchar' }[];
};

type AdvancedProductSearchErrors = {
  products: unknown;
  error: unknown;
  headers: unknown;
  ref: unknown;
};

type AdvancedProductSearchTableData = {
  id: string;
  type: string;
};

export const AdvancedProductSearchFormInitialValues = {
  dataType: FORM_VALUES.searchTypeProduct,
  productSearchDate: INITIAL_SEARCH_DATE,
  productSearchText: undefined,
  referenceDataDate: INITIAL_SEARCH_DATE,
  referenceDataFilters: [{ type: undefined, value: undefined }],
};

// Note that this ticker might not be unique
const getRowTicker = (row: DataQueryProductsItem, dataSource?: string) => {
  if (!row) {
    return;
  }
  switch (dataSource) {
    case 'etfg':
      return row.etf_ticker;
    case 'taqmaster':
    default:
      return row.symbol;
  }
};

const getTableColumnConfig = (name: string) => {
  const newName = startCase(name?.toLowerCase());
  if (newName.startsWith('Tradedon')) {
    return {
      title: 'Traded on ' + newName.substring(8).toUpperCase(),
      render: (value: number) => (value === 1 ? 'Yes' : 'No'),
    };
  }
  if (newName === 'Filedate') {
    return {
      title: 'Data File Date',
    };
  }
  if (newName === 'Security Description') {
    return {
      title: newName,
      width: 300,
    };
  }
  return {
    title: newName,
  };
};

export default function AdvancedProductSearchForm({
  formInstance,
  productInfoForm = false,
  onChange = () => {},
  embedded = false,
}: AdvancedProductSearchFormParams) {
  const formValue = Form.useWatch([], formInstance);
  const dataType = Form.useWatch('dataType', formInstance);

  const [dataSource, _setDataSource] = useState(FORM_VALUES.dataSourceTaqMaster as string | undefined);
  const [searchCriteria, setSearchCriteria] = useState([] as AdvancedProductSearchCriteria[]);
  const [products, setProducts] = useState<DataQueryProducts>({ list: [] });
  const [selectedRows, setSelectedRows] = useState([] as DataQueryProductsItem[]);
  const [resultHeaders, setResultHeaders] = useState({} as AdvancedProductSearchResultHeader);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTablesList, setDataTablesList] = useState([] as AdvancedProductSearchTableData[]);
  const [busy, setBusy] = useState(false);
  const [errors, setError] = useState({} as AdvancedProductSearchErrors);
  const [formValid, setFormValid] = useState(false);

  const resetProducts = useCallback(() => {
    setProducts((currentProducts) => ({ ...currentProducts, list: [], total: 0 }));
  }, [setProducts]);

  const setDataSource = useCallback(
    (dataSource: string | undefined) => {
      _setDataSource(dataSource);
      resetProducts();
    },
    [_setDataSource, resetProducts]
  );

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields({ validateOnly: true });
      setFormValid(true);
    } catch (errorInfo: unknown) {
      setFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  useEffect(() => {
    setTimeout(async () => await setFormValidity());
  }, [formValue]);

  const select = useCallback(
    (items: DataQueryProductsItem[]) => {
      const union = selectedRows.concat(items);
      const selected = uniqWith(union, (a, b) => a.key === b.key);
      setSelectedRows(selected);

      const selectedTickers = selected.map((s) => getRowTicker(s, dataSource)!);
      onChange(selectedTickers);
    },
    [selectedRows, dataSource, onChange]
  );

  const deselect = useCallback(
    (items: DataQueryProductsItem[]) => {
      const selected = items.length ? selectedRows.filter((v) => !items.find((i) => i.key === v.key)) : [];
      setSelectedRows(selected);

      const selectedTickers = selected.map((s) => getRowTicker(s, dataSource)!);
      onChange(selectedTickers);
    },
    [selectedRows, dataSource, onChange]
  );

  const handleDeselect = useCallback(
    (item: DataQueryProductsItem) => () => {
      deselect([item]);
    },
    [deselect]
  );

  const handleSelect = useCallback(
    (row: DataQueryProductsItem, selected: boolean) => {
      if (selected) {
        select([row]);
      } else {
        deselect([row]);
      }
    },
    [deselect, select]
  );

  const handleSelectAll = useCallback(
    (isSelected: boolean, selected: DataQueryProductsItem[]) => {
      if (isSelected) {
        select(selected);
      } else {
        deselect(selected);
      }
    },
    [deselect, select]
  );

  // Debounce returns a function, so we need to use a memo (or ref + effect) here; useCallback won't work because we
  // want the result of lodash/debounce to be memoized, not a function that keeps calling lodash/debounce.
  const getProducts = useMemo(
    () =>
      debounce((criteria, table, pageToGo) => {
        setBusy(true);

        let sorting: { orderBy: string; order: string };

        if (table.includes('taq')) {
          sorting = { orderBy: 'symbol', order: 'ASC' };
        } else if (table.includes('etfg')) {
          sorting = { orderBy: 'inet_ticker', order: 'ASC' };
        }

        defer(async () => {
          try {
            const mappedCriteria = criteria.reduce(
              (
                acc: {
                  [key: string]: string;
                },
                criterion: AdvancedProductSearchCriteria
              ) => {
                if (criterion.type === 'date') {
                  acc[criterion.field!] = (criterion.value as Dayjs).format('YYYYMMDD');
                } else {
                  acc[criterion.field!] = criterion.value as string;
                }

                if (criterion.searchMethod) {
                  acc[criterion.field!] = `${criterion.searchMethod}:${acc[criterion.field!]}`;
                }

                return acc;
              },
              {} as { [key: string]: string }
            );

            const queryParams = new URLSearchParams({
              ...mappedCriteria,
              ...sorting,
              rowCount: DEFAULT_PAGE_SIZE,
              startRow: (pageToGo - 1) * DEFAULT_PAGE_SIZE,
            });

            const token = getAccessTokenFromCookie();
            const { list, total } = await getToJson(`${REFERENCE_GET_TABLE_DATA_URL}${table}/data?${queryParams.toString()}`, token);

            setProducts({ list: list.map((e: DataQueryProducts) => ({ ...e, key: uuid() })), total });
            setBusy(false);
            setError((prevErrors) => omit(prevErrors, 'products') as AdvancedProductSearchErrors);
          } catch (error: unknown) {
            console.error('[AdvancedProductSearchModal] failed to load products from server...', error);
            setProducts({ list: [], total: 0 });
            setError((prevErrors) => ({
              ...prevErrors,
              products: error,
            }));
            setBusy(false);
          }
        });
      }, 250),
    [setProducts, setError, setBusy]
  );

  const errorMessages = useMemo(() => Object.values(errors), [JSON.stringify(errors)]);

  // fetch new headers based on dataSource, if not already there
  useEffect(() => {
    if (!dataSource || !dataTablesList.length || (resultHeaders && resultHeaders[dataSource])) {
      return;
    }

    const tableToSearch = dataTablesList.find(({ type }) => type === dataSource)?.id;

    setBusy(true);
    defer(async () => {
      try {
        if (!tableToSearch) {
          // eslint-disable-next-line no-throw-literal
          throw 'Unable to get info for selected method';
        }

        const token = getAccessTokenFromCookie();
        const headers = await getToJson(`${REFERENCE_GET_TABLE_DATA_URL}${tableToSearch}/structure`, token);
        setResultHeaders((prevHeaders: AdvancedProductSearchResultHeader) => ({ ...prevHeaders, [dataSource]: headers }));
        setBusy(false);
        setError((prevErrors) => omit(prevErrors, 'headers') as AdvancedProductSearchErrors);
      } catch (error) {
        console.error('[AdvancedProductSearchModal] failed to fetch headers for selected reference table...', error);
        setResultHeaders((prevHeaders: AdvancedProductSearchResultHeader) => ({ ...prevHeaders, [dataSource]: [] }));
        setError((prevErrors) => ({
          ...prevErrors,
          headers: error,
        }));
        setBusy(false);
      }
    });
  }, [resultHeaders, dataSource, dataTablesList, setBusy]);

  const resultTableColumns = useMemo(() => {
    return resultHeaders?.[dataSource!]?.map(({ name }) => ({
      dataIndex: name,
      width: 125,
      ...getTableColumnConfig(name),
    }));
  }, [resultHeaders, dataSource]);

  const handleGetProducts = () => {
    if (!formValid || !dataTablesList?.length || !dataSource || !searchCriteria) return;

    const tableToGetProducts = dataTablesList.find(({ type }) => type === dataSource)?.id;
    getProducts(searchCriteria, tableToGetProducts, currentPage);
  };

  // fetch products automatically when not embedded or on currentPage change
  useEffect(() => handleGetProducts(), [currentPage]);
  useEffect(() => {
    if (!embedded) {
      handleGetProducts();
    }
  }, [embedded, formValid, dataTablesList, dataSource, searchCriteria]);

  // mount - fetch reference tables list
  useEffect(() => {
    defer(async () => {
      try {
        const token = getAccessTokenFromCookie();
        const refTables: AdvancedProductSearchTableData[] = await getToJson(`${REFERENCE_GET_TABLE_DATA_URL}tables`, token);
        const supportedRefTables = refTables.length ? refTables.filter(({ type }) => Object.values(SUPPORTED_SOURCES).includes(type)) : [];

        if (!supportedRefTables.length) {
          console.warn('[ReferenceDataFormFields] No reference tables found...');
          // eslint-disable-next-line no-throw-literal
          throw 'No supported reference tables found';
        }
        setDataTablesList(supportedRefTables);
        setError((prevErrors) => omit(prevErrors, 'ref') as AdvancedProductSearchErrors);
      } catch (error) {
        console.error('[ReferenceDataFormFields] failed to fetch reference table list...', error);
        setError((prevErrors) => ({
          ...prevErrors,
          ref: error,
        }));
      }
    });
  }, []);

  // reset on show/hide modal
  useEffect(() => {
    if (!embedded) {
      formInstance.setFieldValue('dataType', AdvancedProductSearchFormInitialValues.dataType);
      onDataTypeChange(AdvancedProductSearchFormInitialValues.dataType);
      onChange([]);
    }
  }, []);

  const onDataTypeChange = (dataType: string) => {
    formInstance.resetFields([
      'productSearchDate',
      'productSearchText',
      'referenceDataSource',
      'referenceDataDate',
      'referenceDataFilters',
    ]);
    setDataSource(dataType === FORM_VALUES.searchTypeProduct ? FORM_VALUES.dataSourceTaqMaster : undefined);
  };

  const tableFooter = () => (
    <Pagination
      current={currentPage}
      pageSize={DEFAULT_PAGE_SIZE}
      total={products.total}
      showSizeChanger={false}
      disabled={products.total! <= DEFAULT_PAGE_SIZE || busy}
      onChange={setCurrentPage}
    />
  );

  return (
    <>
      {!embedded && <Typography.Text strong>Criteria</Typography.Text>}

      <Form.Item label="Data Type" labelAlign="left" name="dataType" rules={[{ required: true, message: 'Select a data type!' }]}>
        <Radio.Group onChange={(event) => onDataTypeChange(event.target.value)}>
          <Radio value={FORM_VALUES.searchTypeProduct}>Product Data</Radio>
          <Radio value={FORM_VALUES.searchTypeReference}>Reference Data</Radio>
        </Radio.Group>
      </Form.Item>
      {dataType === FORM_VALUES.searchTypeProduct && (
        <ProductDataFormFields
          formInstance={formInstance}
          resetProducts={resetProducts}
          setSearchCriteria={setSearchCriteria}
          knownColumns={resultHeaders?.[FORM_VALUES.dataSourceTaqMaster]}
        />
      )}
      {dataType === FORM_VALUES.searchTypeReference && (
        <ReferenceDataFormFields
          formInstance={formInstance}
          refDataTables={dataTablesList}
          dataSource={dataSource}
          resultHeaders={resultHeaders}
          searchCriteria={searchCriteria}
          setDataSource={setDataSource}
          setProducts={setProducts}
          setSearchCriteria={setSearchCriteria}
          embedded={embedded}
        />
      )}

      {!!selectedRows.length && !embedded && (
        <>
          <Typography.Text strong>Selected</Typography.Text>
          <div>
            {selectedRows.map((el) => (
              <Tag key={el.key} className={classes.tag} closable onClose={handleDeselect(el)}>
                {getRowTicker(el, dataSource)}
              </Tag>
            ))}
          </div>
        </>
      )}
      {!embedded && <Typography.Text strong>Results</Typography.Text>}
      {embedded && (
        <Form.Item>
          <Button htmlType="submit" type="primary" onClick={handleGetProducts} disabled={!formValid}>
            Run Query
          </Button>
        </Form.Item>
      )}
      <Table
        bordered
        className={classes.resultTable}
        columns={resultTableColumns}
        dataSource={products.list}
        loading={busy}
        pagination={false}
        scroll={{ x: '100%', y: 300 }}
        size="small"
        rowKey={(row) => row.key}
        {...(resultTableColumns &&
          !embedded && {
            rowSelection: {
              selectedRowKeys: selectedRows.map((v) => v.key),
              columnWidth: 50,
              fixed: true,
              onSelect: handleSelect,
              onSelectAll: handleSelectAll,
              preserveSelectedRowKeys: false,
              selections: [Table.SELECTION_ALL],
            },
          })}
        footer={tableFooter}
      />
      {!embedded && (
        <Typography.Text className={classes.resultTableHelpText}>
          {productInfoForm
            ? `After entering search criteria, choose products you want to get information about and click "Submit".`
            : `After entering search criteria, add products to the data query by checking the box within each
          corresponding row. Products are added by INET ticker after pressing "Done" below.`}
        </Typography.Text>
      )}
      <ErrorComponent errors={errorMessages} />
    </>
  );
}
