import isEqual from 'lodash/isEqual';

import { getUserSubject } from '../../../services/auth';
import { ErrorNotification } from '../../../services/error-notification';
import { WbApi } from '../workbench-api';
import { UsersApi, User } from '../../users/users-api';
import { WbInstance } from './instance';
import { collapseUsers } from '../../users/utils';

export interface InstanceReassign {
  state: 'loading' | 'ready' | 'saving';
  instance: WbInstance;
  objectType: string;
  selectedUserSubject: string;
  users: User[];
}

export async function loadInstanceReassign(usersApi: UsersApi, instance: WbInstance, update: (_: InstanceReassign) => void): Promise<void> {
  const objectType = 'instance';

  update({ state: 'loading', objectType, instance, users: [], selectedUserSubject: '' });

  const reassignmentOptions = (await usersApi.getUsers()) || { allUserGroups: [] };

  const users = collapseUsers(reassignmentOptions.allUserGroups);

  update({
    state: 'ready',
    objectType,
    instance,
    users,
    selectedUserSubject: '',
  });
}

export function instanceReassignSetUser(model: InstanceReassign | undefined, userSubject: string): InstanceReassign | undefined {
  if (!model) {
    return model;
  }

  const result = { ...model };
  result.selectedUserSubject = userSubject;

  return result;
}

export async function instanceReassignSave(
  wbApi: WbApi,
  model: InstanceReassign | undefined,
  refresh: () => Promise<void>,
  update: (_: InstanceReassign | undefined) => void
): Promise<ErrorNotification | undefined> {
  if (!model) {
    return;
  }

  update({ ...model, state: 'saving' });

  const currentUserSubject = await getUserSubject();
  if (!model.selectedUserSubject || isEqual(model.selectedUserSubject, { userId: currentUserSubject })) {
    // Close modal
    update(undefined);
    return;
  }

  const targetUser = model.selectedUserSubject;
  const res = await wbApi.reassignInstance({ id: model.instance.id, user: targetUser });
  if (!res?.ok) {
    update({ ...model, state: 'ready' });
    return { message: 'Error reassigning instance' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}
