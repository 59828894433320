import { createContext, Dispatch, SetStateAction } from 'react';
import { WorkflowCurrentState } from '../../../types';

type CreateUserTableWorkflowContext = {
  uuid?: string;
  processState?: WorkflowCurrentState;
  setProcessState?: Dispatch<SetStateAction<WorkflowCurrentState>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeProcessStepValue?: (step: number, property: string, value: any) => void;
};

export const CreateUserTableWorkflowContext = createContext<CreateUserTableWorkflowContext>({});
